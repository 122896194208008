<template>
  <div class="login_container">
    <div class="login_box">
<!--      头像区-->
      <div class="avatar_box">
        <img src="../../assets/logo.png" alt="">
      </div>
      <!--登陆表单区-->
      <el-form ref="loginformref" class="login_form" :rules="loginformrules" label-width="0px" :model="loginform">
        <!--用户名-->
        <el-form-item prop="username">
          <el-input prefix-icon="el-icon-user" v-model="loginform.username"></el-input>
        </el-form-item>
        <!--密码-->
        <el-form-item prop="password">
          <el-input type="password" prefix-icon="el-icon-lock" v-model="loginform.password" @keyup.native.enter="login"></el-input>
        </el-form-item>
        <!-- 按钮-->
        <el-form-item class="btns">
          <el-button type="primary" @click="login">登录</el-button>
          <el-button type="info" @click="resetloginform">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
import '@/assets/css/global.css'

export default {
  data() {
    return{
      //登陆表单的数据绑定对象
      loginform:{
        username:'',
        password:''
      },
      //表单验证规则对象
      loginformrules:{
        //验证用户名是否合法
        username:[
          { required: true, message: '请输入登录名称', trigger: 'blur' },
          {min: 3, max: 15,message: '长度在 3 到 15 个字符', trigger: 'blur'}
        ],
        //验证密码是否合法
        password:[
          { required: true, message: '请输入登录密码', trigger: 'blur' },
          // {min: 3, max: 30,message: '长度在 3 到 30 个字符', trigger: 'blur'}
        ]
      }
    }
  },
  methods: {
    //点击重置按钮
    resetloginform(){
      this.$refs.loginformref.resetFields();
    },
    //点击登录按钮
    login(){
      this.$refs.loginformref.validate(async valid => {
        if (!valid) return;
        this.loginform.password=this.$md5(this.loginform.password)
        const {data:res} = await this.$http.post('login',this.loginform)
        if(res.code !== 200)return this.$message.error(res.msg)
        this.$message.success("登录成功")
        //登陆成功之后的token 保存到sessionStorage
        window.sessionStorage.setItem("token",res.data.token)
        this.$router.push('/')
      })
    }
  }
}
</script>
<style lang="less">
  html{
    width: 100%;
    height: 100%;
    margin: 0;
    border: 0;
    padding: 0;
  }
  body{
    width: 100%;
    height: 100%;
    margin: 0;
    border: 0;
    padding: 0;
  }
  .login_container{
    width: 100vw;
    height: 100vh;
    background: #757575;
    margin: 0;
    border: 0;
    padding: 0;
  }
  .login_box{
    width: 450px;
    height: 300px;
    background: #ffffff;
    border-radius: 5px;
    position: fixed;
    left: 0;right: 0;top: 0;bottom: 0;
    margin: auto;
  }
  .avatar_box{
    width: 130px;
    height: 130px;
    border: 1px solid #EAEAEA;
    border-radius: 50%;
    padding: 5px;
    box-shadow: 0px 0px 5px 0px #ffffff;
    position: absolute;
    left: 50%;
    transform: translate(-50%,-50%);
    background: #ffffff;
    img{
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background: #eeeeee;
    }
  }
  .login_form{
    position: absolute;
    bottom: 0;
    width: 100%;
    box-sizing: border-box;
    padding: 0px 30px;
  }
  .btns{
    display: flex;
    justify-content: flex-end;
  }
</style>
